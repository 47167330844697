import { Injectable } from "@angular/core";
import { Dataservice } from "../../core/auth";

@Injectable({
	providedIn: "root",
})
export class GetTemplateDataService {
	orderSettingTemplate: any;
	bookingSettingTemplate: any;
	constructor(private dataService: Dataservice) {}

	getMerchantData(username) {
		// https://dev-perkss.azurewebsites.net/api/Merchant/get/pipasha.admin
		this.dataService
			.get("Merchant/get/" + username, [])
			.subscribe((res) => {
        console.log(res)
				const templateData = res["Data"]["TemplateData"];
				localStorage.setItem(
					"templateData",
					JSON.stringify(templateData)
				);
				this.orderSettingTemplate =
					res["Data"]["TemplateData"].OrderSettingTemplate;
				localStorage.setItem(
					"orderSettingTemplate",
					JSON.stringify(this.orderSettingTemplate)
				);

				this.bookingSettingTemplate =
					res["Data"]["TemplateData"].BookingSettingTemplate;
				localStorage.setItem(
					"bookingSettingTemplate",
					JSON.stringify(this.bookingSettingTemplate)
				);
			});
	}
}
